<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Vehicle Groups"
      :showback="false"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Records
          </h2>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['make']"
          >
            <template #item.stats_hash.percent_of_inventory="{value}">
              {{ value }} %
            </template>
            <template #item.stats_hash.percent_of_sold="{value}">
              {{ value }} %
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import VehicleService from '@/services/VehicleService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'

  export default {
    name: 'VehicleLeads',
    mixins: [formatterMixin],
    data () {
      return {
        items: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        singleExpand: false,
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'On Website', value: 'stats_hash.on_website' },
          { text: 'With Photos', value: 'stats_hash.with_photos' },
          { text: 'In Stock', value: 'stats_hash.in_stock' },
          { text: 'Sold', value: 'stats_hash.sold' },
          { text: '% of Inventory', value: 'stats_hash.percent_of_inventory' },
          { text: '% of Sold', value: 'stats_hash.percent_of_sold' },
        ],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      fileName () {
        return `${this.accountName
          .toLowerCase()
          .replace(' ', '_')}_vehicle_groups.csv`
      },
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadGroups()
      },
    },
    created () {
      this.loadGroups()
    },
    methods: {
      loadGroups: function () {
        this.items = []
        this.loading = true
        VehicleService.getVehicleGroups()
          .then(response => {
            this.items = response.data.filter(i => !i.system_generated)
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
    },
  }
</script>
